import { useState, useEffect } from "react";

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return <>{children}</>;

  // this disables server side rendering since we only render the children of this component if we are on the client

  // it is un updated way to do this, so we won't have any rehydration errors

  // <></> is the new Fragment short syntax
};

export default ClientOnly;
